import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { Image } from '../../Image';
import { SmartLink } from '../../SmartLink';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { broadsheetStyles } from './Broadsheet.styles';
import { PhotographersLink } from '../../responsive/atoms/PhotographersLink';
import { ParagraphWrapper } from '../ParagraphWrapper';

const BroadsheetInner = ({ data, isAboveTheFold }) => {
  const topImage = data.topImagePackage?.entity;
  const bodyImage = data.bodyImagePackage?.entity;
  const subscriptionBlock =
    data.subscriptionCard?.entity?.subscriptionBlock?.entity;

  const style = {
    contentVisibility: css`
      content-visibility: auto;
      ${facePaint({
        'contain-intrinsic-size': [
          '0 2321px',
          '0 1724px',
          '0 2110px',
          '0 2228px'
        ]
      })};
    `
  };
  return (
    <section css={[broadsheetStyles.ngbs, style.contentVisibility]}>
      <div css={broadsheetStyles.packageWrapper}>
        <div css={broadsheetStyles.title}>{data.title}</div>
        <div css={broadsheetStyles.hero}>
          {topImage &&
            topImage.image &&
            topImage.image.map(image => (
              <Image
                entity={image.entity}
                loading={isAboveTheFold ? 'eager' : 'lazy'}
                key={image.entity.uuid}
              />
            ))}
          <div css={broadsheetStyles.heroContent}>
            <div css={broadsheetStyles.heroTags}>{data.tags}</div>
            <div css={broadsheetStyles.heroHeader}>{data.header}</div>
            <div css={broadsheetStyles.heroAuthorCredit}>
              {`${t('by')} `}
              <span>{data.author}</span>
            </div>
          </div>
        </div>

        <div css={broadsheetStyles.cont}>
          <div css={broadsheetStyles.main}>
            {topImage && (
              <div css={broadsheetStyles.info}>
                <div css={broadsheetStyles.deck}>{topImage.deck}</div>
                <PhotographersLink
                  color={{ link: '#333 !important', text: '#333' }}
                  photographers={topImage.image?.[0]?.entity?.photographers}
                />
              </div>
            )}
            <div css={broadsheetStyles.text}>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data.broadsheetBody.value }}
              />
              {bodyImage && (
                <div css={broadsheetStyles.image}>
                  {bodyImage.image &&
                    bodyImage.image.map(image => (
                      <Image
                        entity={image.entity}
                        maxWidths={{
                          small_desktop: 658,
                          large_desktop: 854
                        }}
                        loading={isAboveTheFold ? 'eager' : 'lazy'}
                        key={image.entity.uuid}
                      />
                    ))}
                  <div css={broadsheetStyles.imageInfo}>
                    <div css={broadsheetStyles.deck}>{bodyImage.deck}</div>
                    <PhotographersLink
                      color={{ link: '#333 !important', text: '#333' }}
                      photographers={
                        bodyImage.image?.[0]?.entity?.photographers
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            {data.subscriptionButtonLink?.url?.path && (
              <SmartLink
                to={data.subscriptionButtonLink.url.path}
                css={broadsheetStyles.subscribe}
              >
                {data.subscriptionCard?.entity?.subscriptionBlock?.entity
                  ?.subscribeButtonText
                  ? data.subscriptionCard.entity.subscriptionBlock.entity
                      .subscribeButtonText
                  : t('Subscribe')}
              </SmartLink>
            )}
          </div>

          <div css={broadsheetStyles.sidebar}>
            {subscriptionBlock &&
              (subscriptionBlock.cardHeader ||
                subscriptionBlock.cardSubHeader ||
                subscriptionBlock.backgroundImage) &&
              data.subscriptionButtonLink?.url?.path && (
                <div css={broadsheetStyles.sidebarSubsCard}>
                  <SmartLink to={data.subscriptionButtonLink.url.path}>
                    <div css={broadsheetStyles.sidebarSubsCardHeader}>
                      <span className="bs_suscribe">
                        {subscriptionBlock.cardHeader}
                      </span>
                      <span>{subscriptionBlock.cardSubHeader}</span>
                    </div>
                    <Image
                      entity={subscriptionBlock.backgroundImage.entity}
                      loading="lazy"
                    />
                  </SmartLink>
                </div>
              )}
            <div css={broadsheetStyles.toc}>
              {data.magazineToc?.entity?.header && (
                <div css={broadsheetStyles.tocHeader}>
                  {data.magazineToc.entity.header}
                </div>
              )}

              {data.magazineToc?.entity?.entries &&
                data.magazineToc.entity.entries.map(item => {
                  return (
                    item.entity && (
                      <div key={item.entity.id} css={broadsheetStyles.tocItem}>
                        <div css={broadsheetStyles.tocTitle}>
                          {item.entity.title}
                        </div>
                        {item.entity.deck?.value && (
                          <div css={broadsheetStyles.tocDeck}>
                            <span
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: item.entity.deck.value
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BroadsheetInner.defaultProps = {
  isAboveTheFold: false
};

BroadsheetInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const Broadsheet = ParagraphWrapper(BroadsheetInner);
